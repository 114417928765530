/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { mq, useAllResources, useProductIcons } from '../../hooks'
import { Image } from '../Image'
import { RichText } from '../RichText'
import {
  Display,
  TitleLarge,
  TitleMedium,
  TitleSmall,
  TitleXs,
  Body,
  BodySmall,
  Caption,
  TextLink,
  ButtonLink,
  ButtonTransparent,
  OrderedList,
  OrderedListItem,
  UnorderedList,
  UnorderedListItem,
  Bold,
  Italic,
  Underline,
  SuperScript,
  Quote,
  Attribution,
} from '../RichText'

// /////////////////////////////////////////////////////////////////////////////
// Utilities
// /////////////////////////////////////////////////////////////////////////////

const CheckMark = () => (
  <svg viewBox="0 0 20 20" fill="#00A37C">
    <defs />
    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
  </svg>
)

const ValueProp = ({ children }) => (
  <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <div css={{ width: '1.5rem', marginRight: '1rem' }}>
      <CheckMark />
    </div>
    <Body>{children}</Body>
  </div>
)

// /////////////////////////////////////////////////////////////////////////////
// Card Types
// /////////////////////////////////////////////////////////////////////////////

const ProductCard = ({ product }) => {
  const productMap = {
    'Accolade Total Benefits': 'atb',
    'Accolade Total Care': 'atc',
    'Accolade Total Health and Benefits': 'athab',
  }
  const productDetails = {
    atb: {
      icon: useProductIcons('atb'),
      name: 'Accolade Total Benefits',
      tagline: 'Increase engagement with your benefits programs.',
      valueProps: (
        <div>
          <ValueProp>Benefits engagement</ValueProp>
          <ValueProp>Clinical guidance</ValueProp>
        </div>
      ),
    },
    atc: {
      icon: useProductIcons('atc'),
      name: 'Accolade Total Care',
      tagline: 'Guide your people to the best care and providers.',
      valueProps: (
        <div>
          <ValueProp>Benefits engagement</ValueProp>
          <ValueProp>Clinical guidance</ValueProp>
          <ValueProp>Provider matching</ValueProp>
          <ValueProp>Expert medical opinion</ValueProp>
        </div>
      ),
    },
    athab: {
      icon: useProductIcons('athab'),
      name: 'Accolade Total Health and Benefits',
      tagline: 'Improve population health and reduce healthcare costs. ',
      valueProps: (
        <div>
          <ValueProp>Benefits engagement</ValueProp>
          <ValueProp>Clinical guidance</ValueProp>
          <ValueProp>Provider matching</ValueProp>
          <ValueProp>Care management</ValueProp>
          <ValueProp>Provider services</ValueProp>
          <ValueProp>Member services</ValueProp>
        </div>
      ),
    },
  }
  const productData = productDetails[productMap[product]]

  return (
    <div css={{ padding: '2rem' }}>
      <div css={{ display: 'flex', alignItems: 'center', height: '10rem' }}>
        <div css={{ marginRight: '1rem' }}>{productData.icon}</div>
        <TitleMedium>{productData.name}</TitleMedium>
      </div>
      <Body css={{ height: '5rem' }}>{productData.tagline}</Body>
      <TitleXs css={{ textTransform: 'uppercase' }}>Solution Features</TitleXs>
      {productData.valueProps}
    </div>
  )
}

const BlogCard = ({ children }) => {
  return (
    <div>
      <div>

      </div>
      <div>

      </div>
    </div>
  )
}

const ResourceCard = ({ resourceId }) => {
  const resource = useAllResources(resourceId)

  return (
    <div
      css={{
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
      }}
    >
      <div
        css={{
          paddingTop: '56.25%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <div
          css={{
            width: '100%',
            position: 'absolute',
            top: '0px',
            left: '0px',
          }}
        >
          <Image data={{ path: resource.thumbnail }} />
        </div>
      </div>
      <div
        css={{
          flex: '1',
          margin: '2rem',
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'space-between',
          alignContent: 'stretch',
        }}
      >
        <div>
          <TitleMedium>{resource.heading}</TitleMedium>
        </div>
        <div css={{ flex: '1' }}>
          <Body>{resource.abstract}</Body>
        </div>
        <div>
          <TextLink link={resource.slug}>Learn More</TextLink>
        </div>
      </div>
    </div>
  )
}

const QuoteCard = styled.div({
  padding: '4rem',
})

// /////////////////////////////////////////////////////////////////////////////
// Component
// /////////////////////////////////////////////////////////////////////////////

export const Card = ({ data, children }) => {
  const theme = useTheme()
  const { cardStyle, resourceId, solution, containerStyle } = data
  let inner
  switch (cardStyle) {
    case 'BlogCard':
      inner = <BlogCard>{children}</BlogCard>
    case 'Resource':
      inner = <ResourceCard resourceId={resourceId} />
      break
    case 'Quote':
      inner = <QuoteCard style={containerStyle ? containerStyle : {}}>{children}</QuoteCard>
      break
    case 'Solution':
      inner = <ProductCard product={solution} />
      break
    case 'MediaKit':
      inner = (
        <div
          css={{
            ...containerStyle,
          }}
        >
          <RichText data={data} />
        </div>
      )
      break

    case 'ContentBorderBlue':
      inner = (
        <div
          css={{
            padding: theme.spacing.xl,
            paddingTop: theme.spacing._2xl,
            ...containerStyle,
          }}
        >
          <RichText data={data} />
        </div>
      )
      break
    case 'Content':
    case 'ContentNoBorder':
      inner = (
        <div
          css={{
            padding: theme.spacing.xl,
            paddingTop: theme.spacing._4xl,
            ...containerStyle,
          }}
        >
          <RichText data={data} />
        </div>
      )
      break

    default:
      inner = <></>
      break
  }

  return (
    <article
      css={mq({
        backgroundColor: '#ffffff',
        borderBottomWidth: cardStyle == 'ContentBorderBlue' ? '14px' : 'none',
        borderBottomColor:
          cardStyle === 'ContentBorderBlue' ? '#0F449E' : 'none',
        filter:
          cardStyle === 'ContentNoBorder'
            ? 'none'
            : `drop-shadow(0 0 10px ${theme.colors.scaled.grays._03});`,
        margin: cardStyle === 'MediaKit' ? '0' : '1rem 0',
        width: 'auto',
        height: '100%',
        a: { color: theme.colors.primary.actionBlue },
      })}
    >
      {inner}
    </article>
  )
}

export default Card
